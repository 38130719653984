import styled from '@emotion/styled'
import { space, layout, position, typography, color, border, flexbox } from 'styled-system'

const { flex } = flexbox

const Box = styled.div`
  ${space}
  ${flexbox}
  ${layout}
  ${position}
  ${typography}
  ${color}
  ${flex}
  ${border}
`

const Row = styled(Box)(
  {
    display: 'flex',
  },
  flexbox
)

const Column = styled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
  },
  flexbox
)

export { Box, Row, Column }
