import React from 'react'
import { css } from '@emotion/core'
import { get } from 'lodash'
import axios from 'axios'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import qs from 'query-string'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Box, Row, Column } from '../components/ui'

const fields = [
  [
    // row 1
    { label: 'First Name', name: 'firstName' },
    { label: 'Last Name', name: 'lastName' },
  ],
  [
    // row 2
    { label: 'Email', name: 'username' },
    { label: 'Phone Number', name: 'phone' },
  ],
  [
    // row 3
    { label: 'Street Address', name: 'street1' },
    { label: 'Street Address 2', name: 'street2' },
  ],
  [
    // row 4
    { label: 'City', name: 'city' },
    { label: 'Zip Code', name: 'zipCode' },
  ],
]

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  username: Yup.string()
    .email('Invalid email')
    .required('Required'),
  phone: Yup.string().required('Required'),
  street1: Yup.string().required('Required'),
  street2: Yup.string(),
  city: Yup.string().required('Required'),
  zipCode: Yup.string()
    .length(5, 'Must be 5 digits')
    .required('Required'),
})

const FormikInput = ({ field, form, disabled, id, label, type = 'text', inputRef }) => {
  let isTouched = true === get(form.touched, field.name)
  let errorMessage = get(form.errors, field.name)
  let hasError = isTouched && !!errorMessage

  return (
    <Column height={50}>
      <input
        id={id}
        type={type}
        disabled={disabled}
        className={hasError ? 'has-error' : undefined}
        placeholder={label}
        value={field.value || ''}
        name={field.name}
        onBlur={field.onBlur}
        onChange={field.onChange}
      />

      {hasError && (
        <Box fontSize="70%" color="#dc3545" mt={1} px={1}>
          {errorMessage}
        </Box>
      )}
    </Column>
  )
}

const ContactPage = ({ location }) => {
  let [status, setStatus] = React.useState('idle')

  return (
    <Layout routePath="contact">
      <SEO title="Contact" />

      <Box
        width={1}
        bg="#fff"
        flex="1"
        css={css`
          input {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.1;
            color: #495057;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

            &.has-error {
              border-color: #dc3545;
            }
          }
        `}
      >
        <Column width={1} maxWidth={730} mx="auto" mt={4}>
          <Row>
            <h3>Contact Us</h3>
          </Row>

          <Row mb={4}>
            As soon as we receive your information an engineer will review your location and we will
            reach out to schedule your install.
          </Row>

          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              username: '',
              phone: '',
              street1: '',
              street2: '',
              city: '',
              zipCode: '',
            }}
            validationSchema={FormSchema}
            onSubmit={(values, formikBag) => {
              let { ref } = qs.parse(location.search)

              axios
                .post(process.env.GATSBY_CONTACT_URL, {
                  clientType: 1,
                  isLead: true,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  street1: values.street1,
                  street2: values.street2,
                  city: values.city,
                  zipCode: values.zipCode,
                  username: values.username,
                  countryId: null,
                  stateId: null,
                  contacts: [
                    {
                      email: values.username,
                      phone: values.phone,
                      name: `${values.firstName} ${values.lastName}`,
                    },
                  ],
                  attributes: ref
                    ? [
                        {
                          // https://cassclaywireless.unmsapp.com/crm/system/other/custom-attributes/2
                          customAttributeId: 2,
                          value: ref,
                        },
                      ]
                    : [],
                })
                .then(
                  response => {
                    formikBag.resetForm()
                    formikBag.setSubmitting(false)
                    setStatus('submitted')
                    setTimeout(() => setStatus('idle'), 10 * 1000)
                  },
                  err => {
                    formikBag.setSubmitting(false)
                    setStatus('error')
                  }
                )
            }}
          >
            {formikBag => (
              <>
                {fields.map(([field1, field2], index) => (
                  <React.Fragment key={index}>
                    <Row mt={2}>
                      <Box width={1 / 2}>
                        <Field {...field1} component={FormikInput} />
                      </Box>

                      <Box px={2} />

                      <Box width={1 / 2}>
                        <Field {...field2} component={FormikInput} />
                      </Box>
                    </Row>
                  </React.Fragment>
                ))}

                <Row justifyContent="center" my={4}>
                  <button
                    type="submit"
                    disabled={formikBag.isSubmitting}
                    css={{
                      width: 180,
                      padding: '6px 0',
                      border: 'none',
                      color: '#fff',
                      backgroundColor: '#2fb7f1',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                      borderRadius: 16,
                      fontSize: 14,
                      fontWeight: 500,
                      textTransform: 'uppercase',
                      cursor: 'pointer',
                      '&:disabled': {
                        backgroundColor: '#2fb7f199',
                        cursor: 'not-allowed',
                      },
                    }}
                    onClick={formikBag.handleSubmit}
                  >
                    Submit
                  </button>
                </Row>

                {false && (
                  <pre>
                    {JSON.stringify(
                      {
                        status,
                        formikBag,
                      },
                      null,
                      2
                    )}
                  </pre>
                )}
              </>
            )}
          </Formik>

          <Row justifyContent="center" fontStyle="italic">
            {status === 'submitted' && (
              <Box py={3} fontSize={3}>
                Thank you for your submission.
              </Box>
            )}

            {status === 'error' && (
              <Box py={3} fontSize={2}>
                Sorry, there was a <span css={{ color: '#dc3545' }}>problem</span> submitting your
                information. Email us at{' '}
                <a href="mailto:admin@cassclaywireless.com">admin@cassclaywireless.com</a>.
              </Box>
            )}
          </Row>
        </Column>
      </Box>
    </Layout>
  )
}

export default ContactPage
