import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { FaFacebookSquare } from 'react-icons/fa'
// import { FaTwitterSquare } from 'react-icons/fa'
import { Box, Row, Column } from './ui'
import Header from './header'
import logoImg from '../images/logo-sm.png'
import './layout.css'

const Layout = ({ children, routePath }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} routePath={routePath} />

      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minHeight: 'calc(100vh - 100px)',
          backgroundColor: '#eee',
        }}
      >
        <main css={{ flex: 1, display: 'flex', flexDirection: 'column', zIndex: 999 }}>
          {children}
        </main>

        <Column bg="#fff" height={272}>
          <Row
            flexDirection={['column', 'row']}
            justifyContent="center"
            width={1}
            maxWidth={760}
            mx="auto"
            pt={90}
          >
            <Row
              // justifyContent={['center', 'flex-start']}
              justifyContent="center"
              width={[1, 3 / 9]}
              // pr={[0, 4]}
              // pb={[5, 0]}
            >
              <img src={logoImg} alt="Logo" />
            </Row>

            {/*
            <Row width={[1, 2 / 9]} justifyContent="center" alignItems="center">
              <Column width={[250, 150]} fontSize={13} css={{ lineHeight: '1.2rem' }}>
                <Box fontWeight="bold" css={{ textTransform: 'uppercase' }}>
                  Useful Links
                </Box>
                <Box>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
                  amet, consectetur adipiscing.
                </Box>
              </Column>
            </Row>

            <Row width={[1, 2 / 9]} justifyContent="center" alignItems="center" py={[3, 0]}>
              <Column width={[250, 150]} fontSize={13} css={{ lineHeight: '1.2rem' }}>
                <Box fontWeight="bold" css={{ textTransform: 'uppercase' }}>
                  Useful Links
                </Box>
                <Box>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
                  amet, consectetur adipiscing.
                </Box>
              </Column>
            </Row>

            <Row width={[1, 2 / 9]} justifyContent="center" alignItems="center">
              <Column width={[250, 150]} fontSize={13} css={{ lineHeight: '1.2rem' }}>
                <Box fontWeight="bold" css={{ textTransform: 'uppercase' }}>
                  Useful Links
                </Box>
                <Box>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit
                  amet, consectetur adipiscing.
                </Box>
              </Column>
            </Row> */}
          </Row>

          <Column
            // flexDirection={['column', 'row']}
            // flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width={1}
            maxWidth={760}
            height={60}
            mx="auto"
            mt={4}
          >
            <Row
              justifyContent="space-between"
              order={[1, 0]}
              // width={95}
              fontSize={12}
              // px={3}
              pb={3}
            >
              <a
                href="https://www.facebook.com/cassclaywireless/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookSquare size={30} />
              </a>
              {/* <FaTwitterSquare size={30} /> */}
            </Row>

            <Box order={[2, 0]} fontSize={10} fontWeight="bold" pb={3}>
              Copyright © {new Date().getFullYear()} | Cass Clay Wireless | All Rights Reserved
            </Box>
          </Column>
        </Column>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
