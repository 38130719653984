import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { Box, Row, Column } from './ui'
import logoImg from '../images/logo-sm.png'

const linkClass = ({ path, routePath }) => (path === routePath ? 'active' : undefined)

const logoTransition = css`
  .bar1 {
    transform: rotate(-45deg) translate(-3px, 6px);
  }

  .bar2 {
    opacity: 0;
  }

  .bar3 {
    transform: rotate(45deg) translate(-3px, -8px);
  }
`

const HamburgerButton = ({ isOpen, onClick }) => {
  return (
    <div
      onClick={onClick}
      css={css`
        & {
          display: inline-block;
          cursor: pointer;
        }

        .bar1,
        .bar2,
        .bar3 {
          width: 24px;
          height: 3px;
          margin: 4px 0;
          transition: 0.4s;
          /* background-color: rgb(194, 194, 194); */
          background-color: #2fb7f1;
        }

        ${isOpen ? logoTransition : undefined}
      `}
    >
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
  )
}

const MobileNav = ({ routePath }) => {
  let [isOpen, setIsOpen] = React.useState(false)

  return (
    <nav
      className="nav-mobile"
      css={css`
        width: 100%;
        text-align: center;

        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        font-style: normal;

        a {
          display: block;
          color: rgb(194, 194, 194);

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      `}
    >
      <Row height={45} justifyContent="center" alignItems="center" position="relative">
        <Box position="absolute" left={0} px={3}>
          <HamburgerButton isOpen={isOpen} onClick={() => setIsOpen(value => !value)} />
        </Box>

        <Box
          width={1}
          px={44}
          css={css`
            overflow: hidden;
            white-space: nowrap;

            a {
              color: #333;
              font-size: 14px;
              font-weight: bold;
            }
          `}
        >
          <a href="/">Cass Clay Wireless</a>
        </Box>
      </Row>

      <ul
        css={css`
          display: ${isOpen ? 'block' : 'none'};

          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 0;
          }

          a {
            border-top: 1px solid rgba(255, 255, 255, 0.05);
            padding: 0.7em 0;
            color: #333;
          }
          a.active {
            color: #2fb7f1;
          }
        `}
      >
        {/* <li>
          <Link to="/coming-soon" className={linkClass({ path: '', routePath })}>
            Residential
          </Link>
        </li> */}

        {/* <li>
          <Link to="/coming-soon" className={linkClass({ path: '', routePath })}>
            Business
          </Link>
        </li> */}

        <li>
          <a href="https://cassclaywireless.unmsapp.com/crm/client-zone/invoices">Pay Bill</a>
        </li>
        <li>
          <Link to="/contact" className={linkClass({ path: 'contact', routePath })}>
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  )
}

///////////////////////////////////////////////////////////////////////////////

const DesktopNavLink = ({ to, href, text, active = false }) => (
  <Column
    width={90}
    height="100%"
    textAlign="center"
    mx={2}
    borderBottom={active ? '5px solid #2fb7f1' : '5px solid transparent'}
    css={{
      a: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:hover': {
        borderBottom: '5px solid #2fb7f1',
      },
    }}
  >
    {to && <Link to={to}>{text}</Link>}
    {href && (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    )}
  </Column>
)

const DesktopNav = ({ siteTitle, routePath }) => (
  <Row className="nav-desktop">
    <Row flex={1.5} height={100} justifyContent="center" alignItems="center">
      <Box width={72}>
        <Link to="/">
          <img src={logoImg} alt="Logo" />
        </Link>
      </Box>
    </Row>

    <Row
      flex={2}
      justifyContent="center"
      alignItems="center"
      css={{ a: { textTransform: 'uppercase', fontSize: 14 } }}
    >
      {/* <DesktopNavLink to="/coming-soon" text="Residential" /> */}
      {/* <DesktopNavLink to="/coming-soon" text="Business" /> */}
      <DesktopNavLink
        href="https://cassclaywireless.unmsapp.com/crm/client-zone/invoices"
        text="Pay Bill"
      />
      <DesktopNavLink
        to="/contact"
        text="Contact Us"
        active={!!linkClass({ path: 'contact', routePath })}
      />
    </Row>
  </Row>
)

///////////////////////////////////////////////////////////////////////////////

const Header = ({ routePath, siteTitle }) => {
  return (
    <header
      css={css`
        background: #fff;
        color: #3d3d3d;

        @media only screen and (max-width: 640px) {
          .nav-mobile {
            display: block;
          }
          .nav-desktop {
            display: none;
          }
        }

        @media only screen and (min-width: 640px) {
          height: 100px;

          .nav-mobile {
            display: none;
          }

          .nav-desktop {
            display: flex;
          }
        }
      `}
    >
      <MobileNav routePath={routePath} />
      <DesktopNav routePath={routePath} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
